<template>
  <div class="wrap">
    <Sticky>
      <Navbar :title="$t('page.agentCommission')" :border="false" />
      <div class="search_wrap">
        <p class="date_label">{{ $t("teamReport.from") }}</p>
        <div class="input" @click="showFromPicker = true">{{ $format(fromValue, "yy-MM-dd") }}</div>
        <p class="date_label">{{ $t("teamReport.to") }}</p>
        <div class="input" @click="showToPicker = true">{{ $format(toValue, "yy-MM-dd") }}</div>
      </div>
    </Sticky>
    <List :loading-text="$t('Hint.loading')" :finished-text="$t('Hint.notMore')" @load="getData" :loading="loading"
      :finished="!hasMore">
      <div class="list-item" v-for="(items, index) in lists" :key="index">
        <div class="list-item-left">
          <p class="list-item-title">{{ items.title }}</p>
          <span class="list-item-indate">{{
              $format(items.indate)
          }}</span>
        </div>
        <div class="list-item-right">
          <span v-if="items.setup <= 0" class="list-item-money-green">{{ items.setup }}</span>
          <span v-else class="list-item-money">+{{ items.setup }}</span>
          <!-- language-ch 余额 -->
          <span class="list-item-after">{{ $t('newAdd.Balance') }}：{{ items.after }}</span>
        </div>
      </div>
    </List>
    <!-- 开始时间选择 -->
    <Popup v-model="showFromPicker" position="bottom">
      <DatetimePicker v-model="fromValue" type="date" title="" @confirm="(value) => dateSelectChange('from', value)"
        @cancel="showFromPicker = false" :confirm-button-text="$t('My.Button.Two')"
        :cancel-button-text="$t('My.Button.One')" />
    </Popup>
    <!-- 结束时间选择 -->
    <Popup v-model="showToPicker" position="bottom">
      <DatetimePicker v-model="toValue" type="date" title="" @confirm="(value) => dateSelectChange('to', value)"
        :min-date="fromValue" @cancel="showToPicker = false" :confirm-button-text="$t('My.Button.Two')"
        :cancel-button-text="$t('My.Button.One')" />
    </Popup>
  </div>
</template>

<script>
import { post } from "@/utils/newRequest"
import $format from "@/utils/dateFormat"
import { Sticky, List, Popup, DatetimePicker } from "vant"
import Navbar from "@/components/JNav"
export default {
  components: { Sticky, Navbar, List, Popup, DatetimePicker },
  data() {
    return {
      fromValue: new Date(),
      toValue: new Date(),
      loading: false,
      hasMore: true,
      showFromPicker: false,
      showToPicker: false,
      lists: [],
      page: 0
    }
  },
  created() { },
  methods: {
    async getData() {
      this.loading = true;
      this.page += 1;
      try {
        const res = await post("/users/account/lists",
          { subtype: "agent", setup: 0, type: "money", start_day: $format(this.fromValue, "yyyy-MM-dd"), end_day: $format(this.toValue, "yyyy-MM-dd") ,page: this.page});
        const { currentPage, hasMorePages, lists } = res;
        this.hasMore = hasMorePages;
        this.lists = lists
        this.loading = false
      } catch (error) {
        this.hasMore = false;
        this.loading = false
        console.log(error)
        if (error.msg) this.$toast(error.msg);
      }
    },
    dateSelectChange(which, value) {
      if (which == 'from') {
        this.showFromPicker = false
      } else {
        this.showToPicker = false
      }
      this[`${which}Value`] = value
      this.page = 0;
      this.getData();
    },
  }
}
</script>

<style lang="less" scoped>
.search_wrap {
  padding: 10px 20px;

  .date_label {
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0;
    color: var(--light);
  }

  .input {
    padding: 10px 18px;
    border-radius: 6px;
    background: #3E8597;
    border: 1px solid #3E8597;
    color: var(--light);
  }
}

.list-item {
  width: 100%;
  padding: calc(10rem / 16);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: calc(1rem / 16);
  border-bottom-style: solid;
  border-bottom-color: #e8e8e8;
  box-sizing: border-box;
}

.list-item-left {
  flex: 1;
}

.list-item-right {
  display: flex;
  align-items: flex-end;
}

.list-item-title {
  flex: 1;
  height: calc(16rem / 16);
  font-size: calc(13rem / 16);
  color: #242424;
}

.list-item-indate {
  margin-top: calc(6rem / 16);
  font-size: calc(12rem / 16);
  color: #888888;
}

.list-item-money,
.list-item-money-green {
  padding-left: calc(6rem / 16);
  font-size: calc(17rem / 16);
  color: #dc1323;
}

.list-item-money-green {
  color: #1bb019;
}

.list-item-after {
  margin-top: calc(3rem / 16);
  font-size: calc(11rem / 16);
  line-height: calc(17rem / 16);
  padding-left: calc(6rem / 16);
  color: #555555;
}
</style>